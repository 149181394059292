import { useEffect, useRef, useReducer } from "react";
import { getData } from "../helpers/lambdaHelper";
import _isEqual from "lodash/isEqual";

const useExtentOfGeos = ({ geoIDs, year }) => {
    const cache = useRef({});
    const initialState = {
        status: "idle",
        error: null,
        extentOfGeos: null,
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                if (state.status === "fetching") return state;
                return { ...initialState, status: "fetching" };
            case "FETCHED":
                if (state.status === "fetched" && _isEqual(state.extentOfGeos, action.payload)) return state;
                return { ...initialState, status: "fetched", extentOfGeos: action.payload };
            case "FETCH_ERROR":
                return { ...initialState, status: "error", error: action.payload };
            case "RESET":
                return state.status !== 'idle' ? initialState : state;
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {
        if (!geoIDs || !geoIDs[0]) {
            dispatch({ type: "RESET" });
            return;
        }
        const fetchData = async () => {
            const geoIDsStr = geoIDs.sort().join(",");
            if (cache.current && cache.current[geoIDsStr]) {
                const out = cache.current[geoIDsStr];
                dispatch({ type: out === "fetching" ? "FETCHING" : "FETCHED", payload: out });
            } else {
                cache.current[geoIDsStr] = "fetching";
                dispatch({ type: "FETCHING" });
                try {
                    const newData = await getData("fetchExtentOfGeos", {
                        ids: geoIDsStr,
                        year: year
                    });

                    // add the new recods to the cache
                    cache.current[geoIDsStr] = newData;

                    dispatch({ type: "FETCHED", payload: newData });
                } catch (error) {
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                    throw error;
                }
            }
        };

        fetchData();
    }, [geoIDs, year]);

    return state;
};

export default useExtentOfGeos;
