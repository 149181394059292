import React, { useContext } from "react";
import appContext from "../../context/appContext";
import { Button } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ReportNotAvailableButton from "./ReportNotAvailableButton";

const OpenDownloadModalButton = ({ a11yTitle, reportLocations, template, reportName }) => {
    const { setDownloadModalProps } = useContext(appContext);

    // throw an error for Connecticut counties for reports with BEA data
    const connecticutCounties = ["9011", "9001", "9007", "9009", "9015", "9013", "9005", "9003"];
    const connecticutCountyEquivs = ["9110", "9170", "9180", "9130", "9190", "9140", "9120", "9160", "9150"];
    if (
        [
            "publiclands",
            "trends",
            "usfws",
            "usfws-extended",
            "blm",
            "fs",
            "agriculture",
            "government",
            "mining",
            "nonlabor",
            "services",
            "timber",
            "tourism",
            "fedpayments"
        ].includes(template) &&
        reportLocations.some(
            (reportLocation) =>
                connecticutCounties.includes(reportLocation.id) || connecticutCountyEquivs.includes(reportLocation.id)
        )
    ) {
        return (
            <ReportNotAvailableButton
                notificationText={
                    "In 2022, the Census Bureau adopted Connecticut's nine planning regions as county-equivalent geographic units for purposes of collecting, tabulating, and disseminating statistical data, replacing the eight counties which ceased to function as governmental and administrative entities in 1960. Reports that have data over time for these geographies are not currently available."
                }
            />
        );
    }

    return (
        <Button
            a11yTitle={a11yTitle}
            color="heBlue-4"
            margin={{ horizontal: "auto" }}
            icon={<FontAwesomeIcon icon={faDownload} size="lg" />}
            onClick={() => {
                setDownloadModalProps({
                    reportLocations: reportLocations,
                    isVisible: true,
                    template: template,
                    reportName: reportName,
                });
            }}
        />
    );
};

export default OpenDownloadModalButton;
