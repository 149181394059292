const getMergedExtent = (extentArray) => {
    let newExtent = {
        minLat: 9999,
        minLon: 9999,
        maxLat: -9999,
        maxLon: -9999,
    };

    extentArray.forEach((extent) => {
        if (extent) {
            if (extent.minLat < newExtent.minLat) {
                newExtent.minLat = extent.minLat;
            }
            if (extent.minLon < newExtent.minLon) {
                newExtent.minLon = extent.minLon;
            }
            if (extent.maxLat > newExtent.maxLat) {
                newExtent.maxLat = extent.maxLat;
            }
            if (extent.maxLon > newExtent.maxLon) {
                newExtent.maxLon = extent.maxLon;
            }
        }
    });

    return newExtent;
};
const getBufferedExtent = (extent, bufferScale) => {
    const maxBuffer = 1; // degrees
    let latBuffer = Math.min(maxBuffer, ((extent.maxLat - extent.minLat) * bufferScale) / 2);
    let lonBuffer = Math.min(maxBuffer, ((extent.maxLon - extent.minLon) * bufferScale) / 2);
    return {
        minLat: extent.minLat - latBuffer,
        maxLat: extent.maxLat + latBuffer,
        minLon: extent.minLon - lonBuffer,
        maxLon: extent.maxLon + lonBuffer,
    };
};
const extentsEqual = (extent1, extent2) => {
    if (!extent1 || !extent2) return false;
    let eq = true;
    ["minLat", "minLon", "maxLat", "maxLon"].forEach((dim) => {
        if (extent1[dim] !== extent2[dim]) {
            eq = false;
        }
    });
    return eq;
};

export { getMergedExtent, extentsEqual, getBufferedExtent };

