import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Plain = styled.div`
    position: absolute;
`;

const Styled = styled(Plain)`
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 5px;
    background-color: #fff;
`;

const positionStyles = {
    "lower-left": {
        left: "10px",
        bottom: "40px",
    },
    "lower-right": {
        right: "10px",
        bottom: "10px",
    },
    "top-right": {
        right: "10px",
        top: "10px",
    },
    "top-left": {
        left: "50px",
        top: "10px",
    },
};

const ControlContainer = ({ position, children, style, plain, ...rest }) => {
    if (!children) return null;
    const props = { style: { ...positionStyles[position], ...style }, ...rest };

    return plain ? <Plain {...props}>{children}</Plain> : <Styled {...props}>{children}</Styled>;
};

ControlContainer.protoTypes = {
    position: PropTypes.oneOf(["lower-left", "lower-right", "top-right", "top-left"]),
    plain: PropTypes.bool,
};

ControlContainer.defaultProps = {
    position: "lower-left",
    plain: false,
};

export default ControlContainer;
