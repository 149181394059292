import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";

// Documentation on Grommet themes:
// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// The base theme this extends and overrides can be found here:
// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js

const fontSizes = ["1rem", "1.2rem", "1.4rem", "1.8rem", "2.0rem", "2rem"];
const bodyHeight = "1.3";
const headingHeight = "1.2";

const relativeSizeFactor = {
    xxsmall: 0.5,
    xsmall: 0.75,
    small: 0.9,
    medium: 1,
    large: 1.125,
    xlarge: 1.25,
    xxlarge: 1.5,
    xxxlarge: 2,
}
const screenSizeFactor = {
    mobile: 0.75,
    tablet: 0.8,
    desktop: 1
}

const getRelativeBodySize = (relativeSize, screenSize) => {
    const baseSize = 20; // assumes 'medium' on 'desktop'
    return parseInt(baseSize*relativeSizeFactor[relativeSize]*screenSizeFactor[screenSize],10).toString() +'px';
};
const getRelativeHeadingSize = (relativeSize, screenSize) => {
    const baseSize = 28; // assumes 'medium' on 'desktop'
    return parseInt(baseSize*relativeSizeFactor[relativeSize]*screenSizeFactor[screenSize],10).toString() +'px';
};

const colors = {
    brand: "#0D4D80",
    heDarkBlue: "#0D4D80",
    "heDarkBlue-2": "#2E5573",
    "heDarkBlue-3": "#031E33",
    "heBlue": "#0D4D80",
    "heBlue-2": "#3D7199",
    "heBlue-3": "#ECF1F5",
    "heBlue-4": "#2984CD",
    heLightBlue: "#6580AA",
    heRed: "#820915",
    "heRed-2": "#9B3A44",
    "heRed-3": "#F0E2E3",
    heOrange: "#D8852A",
    heGreen: "#718C49",
    text: {
        color: {
            dark: "#f2f2f2",
            light: "#333333",
        },
    },
    heading: "#58585B",
    control: "text",
    "graph-0": "#0D4D80",
    "graph-1": "#157BCD",
    "graph-2": "#3C6E95",
    "graph-3": "#5296CD",
    "graph-4": "#082E4D",
};

const bodyFontSizes = {
    xsmall: {
        size: "8pt",
    },
    small: {
        size: fontSizes[0],
        height: bodyHeight,
    },
    medium: {
        size: fontSizes[1],
        height: bodyHeight,
    },
    large: {
        size: fontSizes[2],
        height: bodyHeight,
    },
    xlarge: {
        size: fontSizes[3],
        height: bodyHeight,
    },
    xxlarge: {
        size: fontSizes[4],
        height: bodyHeight,
    },
    xxxlarge: {
        size: fontSizes[5],
        height: bodyHeight,
    },
};

const headingFontSizes = {
    small: {
        size: fontSizes[0],
        height: headingHeight,
    },
    medium: {
        size: fontSizes[1],
        height: headingHeight,
    },
    large: {
        size: fontSizes[2],
        height: headingHeight,
    },
    larger: {
        size: "24px",
        height: headingHeight,
    },
    xlarge: {
        size: fontSizes[3],
        height: headingHeight,
    },
    xxlarge: {
        size: fontSizes[4],
        height: headingHeight,
    },
};

const HeMainTheme = deepMerge(grommet, {
    global: {
        font: {
            family: "Open Sans, sans-serif",
        },
        colors: colors,

        // drop: {
        //   extend: (props: any) => `animation-duration: 0s;`
        // },
        animation: {
            duration: false,
        },
        focus: {
            border: {
                // remove the light green border from around selected components
                color: "text",
                size: "xsmall"
            },
        },
        edgeSize: {
            "none": "0px",
            "hair": "1px",
            "xxsmall": "3px",
            "xsmall": "6px",
            "small": "12px",
            "medium": "24px",
            "large": "48px",
            "xlarge": "96px",
            "responsiveBreakpoint": "small"
          }
    },
    text: bodyFontSizes,
    paragraph: bodyFontSizes,
    heading: {
        weight: 900,
        level: {
            "1": headingFontSizes,
            "2": headingFontSizes,
            "3": headingFontSizes,
            "4": headingFontSizes,
            "5": headingFontSizes,
            "6": headingFontSizes,
        },
    },
    anchor: {
        color: {
            dark: "light-2",
            light: "dark-2",
        },
    },
    button: {
        default: {
            padding: {
                horizontal: 'xsmall',
                vertical: 'xsmall'
            }
        }
    },
    input: {
        padding: {
            horizontal: '.25em',
            vertical: '.25em'
        }
    },
    select: {
        step: 10,
        options: {
            container: {
                pad: "xsmall"
            }
        }
    },
    textInput: {
        size: "1.5rem",
    },
    tab: {
        active: {
            color: {
                dark: "heBlue-3",
                light: "heDarkBlue-2",
            },
            weight: 900,
        },
        hover: {
            color: "text",
        },
        border: {
            active: {
                color: {
                    dark: "heBlue-3",
                    light: "heBlue-2",
                },
            },
            hover: {
                color: "text",
            },
            // hide the underline from unselected tab names
            color: {
                dark: "#00000000",
                light: "#00000000",
            },
        },
        color: {
            dark: "heBlue-3",
            light: "heDarkBlue-3",
        },
    },
    tip: {
        content: {
            background: "rgba(250,250,250,.9)",
        }
        
    }
});

export { colors, getRelativeBodySize, getRelativeHeadingSize };
export default HeMainTheme;
