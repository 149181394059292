import React, { useState, useContext, useEffect } from "react";
import appContext from "../context/appContext";
import { Box, Button, Layer, Text, ThemeContext, Heading, Header, Grid } from "grommet";
import { downloadReport as downloadReportPython } from "../../../helpers/he-reportBuild-python";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faThumbsUp, faThumbsDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf, faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import RelatedReportsList from "./ReportList/RelatedReportsList";
import { SearchInput } from "../../../components/HeLocationPicker";
import reports from "../assets/reports.json";

const customLayerTheme = {
    layer: {
        zIndex: 20,
        container: {
            zIndex: 25,
        },
    },
};

const DownloadModal = () => {
    const {
        downloadModalProps,
        setDownloadModalProps,
        benchmarkGeo,
        setBenchmarkGeo
        /*, setLatestPrintParameters */,
    } = useContext(appContext);
    const [progressIndicatorContent, set_progressIndicatorContent] = useState(null);
    const [reportFormat, set_reportFormat] = useState(null);
    // const [benchmarkGeo, set_benchmarkGeo] = useState({ id: "0", label: "United States" });
    const [downloadingReports, set_downloadingReports] = useState([]);
    const [downloadedReports, set_downloadedReports] = useState([]);
    const [erroredReports, set_erroredReports] = useState([]);
    // const [combinedGroupType, set_combinedGroupType] = useState(null);

    useEffect(() => {
        if (!downloadModalProps.isVisible) {
            set_progressIndicatorContent(null);
            set_downloadingReports([]);
            set_downloadedReports([]);
            set_erroredReports([]);
        }
    }, [downloadModalProps]);

    if (!downloadModalProps.isVisible) return null;

    const runDownload = (format, reportName, templateName) => {
        if (format) {
            set_reportFormat(format);
        }
        set_downloadingReports(downloadingReports.map((a) => a).concat([templateName]));
        set_progressIndicatorContent(
            <>
                <Box animation={{ type: "fadeIn", delay: 250, duration: 250 }} width="100px" align="center">
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" color="heBlue-2" />
                </Box>
                <Text size="xlarge" weight="bold" color="heBlue-2">
                    {reportName} Report is downloading
                </Text>
            </>
        );

        let geoId = downloadModalProps.reportLocations[0].id;

        const multipleGeos =
            downloadModalProps.reportLocations.length > 1 ||
            (downloadModalProps.reportLocations.length === 1 && downloadModalProps.reportLocations[0].id !== geoId);

        const geos = multipleGeos
            ? downloadModalProps.reportLocations
                  .filter((sl) => sl.validReports.includes(templateName))
                  .map((sl) => sl.id)
                  .join(",")
            : geoId;

        // account for USFWS and wildfire extended report exception
        if (
            templateName === "usfws" &&
            !downloadModalProps.reportLocations.some((geo) => ["place", "tract", "tribal", "cd", "cousub"].includes(geo.geo_level))
        ) {
            templateName = "usfws-extended";
        }
        if (
            templateName === "wildfire" &&
            !downloadModalProps.reportLocations.some((geo) => ["place", "tract", "tribal"].includes(geo.geo_level))
        ) {
            templateName = "wildfire-extended";
        }

        const parameters = {
            report_template_name: templateName,
            report_sub_heading: "",
            report_format: format ? format : reportFormat,
            request_source: "economic-profile-system",
            position_keys: [
                {
                    position_key: 1,
                    geo_codes: geos,
                    override_name: "",
                    is_aggregate: false,
                    is_region: false,
                    is_benchmark: false,
                },
                {
                    position_key: 2,
                    geo_codes: geos,
                    override_name: "",
                    is_aggregate: true,
                    is_region: true,
                    is_benchmark: false,
                },
                {
                    position_key: 3,
                    geo_codes: benchmarkGeo.id,
                    override_name: benchmarkGeo.label,
                    is_aggregate: true,
                    is_region: false,
                    is_benchmark: true,
                },
            ],
            base64_graphics: [],
        };

        // // set the print parameter for the feedback module
        // setLatestPrintParameters(parameters)

        // // download the .NET version
        // downloadReportDotNet(
        //     parameters,
        //     {
        //         fileName: reportName
        //             .replace(/\./g, "")
        //             .split(" ")
        //             .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        //             .join("")+"_old",
        //     }
        // ).then(
        //     () => {
        //         set_progressIndicatorContent(
        //             <>
        //                 <Box animation={{ type: "slideUp", delay: 250, duration: 750 }} width="100px" align="center">
        //                     <FontAwesomeIcon icon={faThumbsUp} size="3x" color="#00C781" />
        //                 </Box>
        //                 <Text size="xlarge" weight="bold" color="status-ok" textAlign="center">
        //                     Check your download folder!
        //                 </Text>
        //             </>
        //         );
        //         //template name fix for extended reports
        //         const templateName_fixed = templateName.replace("-extended", "");
        //         set_downloadedReports(downloadedReports.map((a) => a).concat([templateName_fixed]));
        //     },
        //     (err) => {
        //         set_progressIndicatorContent(
        //             <Text size="large" color="status-error">
        //                 <FontAwesomeIcon icon={faThumbsDown} />
        //                 Looks like there was an problem!
        //             </Text>
        //         );
        //         set_erroredReports(erroredReports.map((a) => a).concat([templateName]));
        //     }
        // );

        // download the python verison
        downloadReportPython(parameters, {
            fileName: reportName
                .replace(/\./g, "")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(""),
            auth_key: "58f0d42d-0ed7-493d-afe4-f042d5c3aac3",
        }).then(
            () => {
                set_progressIndicatorContent(
                    <>
                        <Box animation={{ type: "slideUp", delay: 250, duration: 750 }} width="100px" align="center">
                            <FontAwesomeIcon icon={faThumbsUp} size="3x" color="#00C781" />
                        </Box>
                        <Text size="xlarge" weight="bold" color="status-ok" textAlign="center">
                            Check your download folder!
                        </Text>
                    </>
                );
                //template name fix for extended reports
                const templateName_fixed = templateName.replace("-extended", "");
                set_downloadedReports(downloadedReports.map((a) => a).concat([templateName_fixed]));
            },
            (err) => {
                set_progressIndicatorContent(
                    <Text size="large" color="status-error">
                        <FontAwesomeIcon icon={faThumbsDown} />
                        Looks like there was an problem!
                    </Text>
                );
                set_erroredReports(erroredReports.map((a) => a).concat([templateName]));
            }
        );
    };

    const DownloadButton = ({ format }) => {
        const pdfDisabled = format === "pdf" && downloadModalProps.reportLocations.length > 5;
        return (
            <Button
                fill
                plain
                disabled={pdfDisabled}
                tip={pdfDisabled ? "PDF not available for reports with > 5 locations." : ""}
                gap="xsmall"
                icon={format === "pdf" ? <FontAwesomeIcon icon={faFilePdf} /> : <FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                    runDownload(format, downloadModalProps.reportName, downloadModalProps.template);
                }}
                label={format === "pdf" ? "PDF" : "Excel"}
            />
        );
    };
    const ModalContentStage1 = () => {

        return (
            <Box fill>
                <Box pad={{ vertical: "small", horizontal: "small" }}>
                    <Text weight="bold">
                        Selected Location{downloadModalProps.reportLocations.length > 1 ? "(s)" : ""}
                    </Text>
                    <Box direction="row" gap="xsmall" wrap>
                        <Box pad={{ vertical: "xsmall", horizontal: "small" }} background="light-3">
                            {downloadModalProps.reportLocations
                                .filter((sl) => sl.validReports.includes(downloadModalProps.template))
                                .map((sl) => sl.name || sl.label)
                                .join("; ")}
                        </Box>
                    </Box>
                </Box>
                <Box pad={{ vertical: "small", horizontal: "small" }}>
                    <Text weight="bold">Comparison Location</Text>
                    <Box background="white">
                        <SearchInput
                            setSearchLocation={(newVal) => {
                                setBenchmarkGeo(newVal);
                            }}
                            placeholder={benchmarkGeo.label}
                            geoTypes={"he-county,he-state,he-nation,he-tribal,he-portion"}
                            autoFocus={false}
                        />
                    </Box>
                </Box>
                {/* {multipleGeos && (
                    <Box pad={{ vertical: "small", horizontal: "small" }}>
                        <Text weight="bold">Combined Area Name</Text>
                        <Box background="white">
                            <TextInput
                                placeholder={"Default: Combined Area"}
                                value={combinedAreaNameTempVal}
                                onChange={(event) => set_combinedAreaNameTempVal(event.target.value)}
                                onBlur={() => setCombinedAreaName(combinedAreaNameTempVal)}
                            />
                        </Box>
                    </Box>
                )} */}
                <Box
                    direction="row"
                    justify="end"
                    align="center"
                    gap="xsmall"
                    pad={{ top: "medium", bottom: "small", horizontal: "small" }}
                >
                    <Box>
                        <Text weight="bold">Download:</Text>
                    </Box>
                    <Box direction="row">
                        <Box
                            fill
                            background={{
                                color: "heRed",
                                opacity: downloadModalProps.reportLocations.length > 5 ? 0.5 : 1,
                            }}
                            pad={{ vertical: "xsmall", left: "small", right: "xsmall" }}
                            round={{ size: "small", corner: "left" }}
                        >
                            <DownloadButton format="pdf" report="report" />
                        </Box>
                        <Box
                            fill
                            background="heGreen"
                            pad={{ vertical: "xsmall", left: "xsmall", right: "small" }}
                            round={{ size: "small", corner: "right" }}
                        >
                            <DownloadButton format="xlsx" report="report" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };
    const ModalContentStage2 = () => {
        const reportCategory = reports.find((report) => report.template === downloadModalProps.template).category;
        return (
            <Box fill>
                <Box pad={{ vertical: "large", horizontal: "large" }}>
                    <Grid columns={["100px", "1fr"]} gap="small" align="center">
                        {progressIndicatorContent}
                    </Grid>
                </Box>

                <Box pad="small" overflow="auto" background="light-1" height={{ max: "33vh" }}>
                    <Heading level="3">You may be interested in other {reportCategory} Reports:</Heading>
                    <Grid fill columns={["1fr", "1fr"]} gap="xsmall">
                        <RelatedReportsList
                            downloadingReports={downloadingReports}
                            erroredReports={erroredReports}
                            previouslyDownloadedReports={downloadedReports}
                            category={reportCategory}
                            onDownloadReport={(reportName, reportTemplate) => {
                                runDownload(null, reportName, reportTemplate);
                            }}
                        />
                    </Grid>
                </Box>
            </Box>
        );
    };

    return (
        <ThemeContext.Extend value={customLayerTheme}>
            <Layer
                responsive={true}
                modal={true}
                onEsc={() => setDownloadModalProps({ isVisible: false })}
                onClickOutside={() => setDownloadModalProps({ isVisible: false })}
                margin="small"
            >
                <Box width={{ min: "50vw" }} round="xsmall" justify="between">
                    <Header background="dark-1" pad={{ vertical: "xsmall", left: "xsmall", right: "small" }}>
                        <Text weight="bold" size="large">
                            Download Report
                        </Text>
                        <Button
                            plain
                            icon={
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => setDownloadModalProps({ isVisible: false })}
                                />
                            }
                        />
                    </Header>
                    {!progressIndicatorContent ? <ModalContentStage1 /> : <ModalContentStage2 />}
                </Box>
            </Layer>
        </ThemeContext.Extend>
    );
};

export default DownloadModal;
